
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {designation_list} from "@/core/data/genericData";
import axios from "axios";

export default defineComponent({
  name: "add-branch-modal",
  props: {    
    
  },
  components: {
    
    },
    data() {
      return {
        value: []
      }
    },

  setup(props) {

    const formRef = ref<null | HTMLFormElement>(null);
    const addtagsModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const formData = ref({
      name: "",      
      
    });    

    const rules = ref({
      tag_name: [
        {
          required: true,
          message: "Tag is required",
          trigger: "change",          
        },        
      ],
      
    });

      const setTagsData = async (data) => {

      const db_data = {
        "tag_name": data.tag_select,
        "created_user_id": "1",
        }

        try {
        
          await axios.post(
            process.env.VUE_APP_CUSTOME_APP_API_URL+"product/create_tag",
            db_data,
          {
            headers: {
              "x-api-key": "BvFPhsxvLB5cTrNLbWdGC2aayThFkC6Z4iPcXeDh"
            },
          }
        ).then(({ data }) => {

          console.log(db_data);
          console.log(data);
        
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }


    const submit = async () => {
      if (!formRef.value) {
        return;
      }      

      await setTagsData(formData.value);

      formRef.value.validate ((valid) => {
        if (valid) {
      
          loading.value = true;

          setTimeout(() => {
            loading.value = false;

            Swal.fire({
              text: "Form has been successfully submitted!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              hideModal(addtagsModalRef.value);              
            });
          }, 2000);
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      addtagsModalRef,
    };
  },
});
